import clsx from 'clsx'
import { useState } from 'preact/hooks'
import type { Page_Layout_Elements_Accordion } from '../../__generated__/types'

export default function AccordionItems({ fields }: { fields: Page_Layout_Elements_Accordion }) {
  const [currentItemIndex, setCurrentItemIndex] = useState(0)

  function handleCurrentIndex(index: number) {
    if (index === currentItemIndex) {
      setCurrentItemIndex(-1)
    } else {
      setCurrentItemIndex(index)
    }
  }

  return (
    <div>
      {fields.items?.map((edge, index) => {
        const open = currentItemIndex === index

        return (
          <div role="button" onClick={() => handleCurrentIndex(index)} class="group bg-white p-4 lg:p-7 rounded-lg mb-2 shadow-sm">
            <div class="flex justify-between items-start">
              <h3 class="group-hover:underline text-base lg:text-xl ">{edge?.title}</h3>
              <svg
                class={clsx(
                  open ? '-rotate-0' : '-rotate-90',
                  'ml-4 lg:ml-10 mt-[0.1rem] transition-transform min-w-[1.5rem] min-h-[1.875rem]'
                )}
                width="24"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 30"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                x="0px"
                y="0px"
              >
                <polyline points="6 9 12 15 18 9" />
              </svg>
            </div>
            {edge?.content && (
              <div class={clsx(open ? 'h-auto pt-8' : 'h-0 pt-0', 'overflow-hidden transition-all')}>
                <div class="prose-sm" dangerouslySetInnerHTML={{ __html: edge.content }}></div>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
